import de from '../locales/de.json';
import en from '../locales/en.json';
import es from '../locales/es.json';
import fr from '../locales/fr.json';
import it from '../locales/it.json';
import pl from '../locales/pl.json';
import ru from '../locales/ru.json';

export default defineI18nConfig(() => {
    const defaultLocale = 'en';
    const locales = useRuntimeConfig().public.i18n.configLocales;

    const messages = { de, en, es, fr, it, pl, ru };

    function replacePlaceholders(obj) {
        if (typeof obj === 'string') {
            return obj.replace(/:([a-zA-Z0-9_]+)/g, '{$1}');
        } else if (typeof obj === 'object' && obj !== null) {
            for (const key in obj) {
                obj[key] = replacePlaceholders(obj[key]);
            }
            return obj;
        } else {
            return obj;
        }
    }

    for (const locale in messages) {
        replacePlaceholders(messages[locale]);
    }

    return {
        legacy: false,
        globalInjection: true,
        strategy: 'prefix',
        detectBrowserLanguage: false,
        locale: useLocaleStore().getLanguage(),
        defaultLocale,
        locales,
        messages,
        pluralRules: {
            'ru': function(choice, choicesLength) {
                if (choice === 0) {
                    return 0;
                }

                const teen = choice > 10 && choice < 20;
                const endsWithOne = choice % 10 === 1;

                if (choicesLength < 4) {
                    return (!teen && endsWithOne) ? 1 : 2;
                }
                if (!teen && endsWithOne) {
                    return 1;
                }
                if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
                    return 2;
                }

                return (choicesLength < 4) ? 2 : 3;
            }
        }
    };
});
